import { GenerateGUID } from "@/utilities";
import { API } from "./api"

const packetSize = 65536;

export type UploadFileResult = { idUpload: string, idFile: string, name: string }

export type OptionsUpload = {
  progress?: (info: UploadInfo) => void,
  stop?: () => void,
}

export type UploadInfo = {
  id: string,
  percentage: number,
  finish: boolean,
  buffer?: ArrayBuffer,
  splitBuffer: ArrayBuffer[],
  splitBufferSend: boolean[],
  percentageSend: 0,
}

export function UploadFile(file, options?: OptionsUpload): Promise<UploadFileResult> {
  if (!file) throw new Error("invalid_file");
  const infoUpload : UploadInfo = {
    id: GenerateGUID(),
    percentage: 0,
    finish: false,

    buffer: <ArrayBuffer | undefined>undefined,
    splitBuffer: <ArrayBuffer[]>[],
    splitBufferSend: <boolean[]>[],
    percentageSend: 0,
  }

  return new Promise((res, rej) => {
    const reader = new FileReader();
    let stopUpload = false;

    if (options) {
      options.stop = () => {
        API.call("upload.StopFile", infoUpload.id);
        stopUpload = true;
      }
    }

    reader.onload = () => {
      if (!reader.result || typeof reader.result == 'string') return rej("Invalid file content");
      const iteration = Math.ceil(reader.result.byteLength / packetSize);

      const result = reader.result;
      const splitBuffer: ArrayBuffer[] = [];
      const splitBufferSend: boolean[] = [];

      // Generate list of packet grouped by index
      for (let packet = 0; packet < iteration; packet++) {
        splitBuffer.push(result.slice(packet * packetSize, (packet + 1) * packetSize));
        splitBufferSend.push(false);
      }

      infoUpload.buffer = result;
      infoUpload.splitBuffer = splitBuffer;
      infoUpload.splitBufferSend = splitBufferSend;
      infoUpload.percentageSend = 0;

      API.call("upload.InitializeFile", infoUpload.id, result.byteLength, splitBuffer.length).then(async () => {
        let finalID = '';

        let all = [];

        for (let buff = 0; buff < splitBuffer.length; buff++) {
          if (stopUpload) {
            rej("upload_aborted");
            return;
          }
          
          try{
            all.push(
              API.callAdvanced("upload.SendPacket", { maxTime: undefined }, infoUpload.id, splitBuffer[buff], buff).then((result) => {
                const newPercentage = buff / (splitBuffer.length - 1);
                if(newPercentage > infoUpload.percentage){
                  infoUpload.percentage = newPercentage;
                  options?.progress?.(infoUpload);
                }
                if (result) finalID = result as any; //Receive final id
              })
            );
          }catch(e){
            console.warn(e);
          }
        }

        await Promise.all(all);

        infoUpload.finish = true;
        infoUpload.percentage = 1;
        options?.progress?.(infoUpload);

        res({ idUpload: finalID, idFile: infoUpload.id, name: file.name });
      }).catch(rej);
    }
    reader.readAsArrayBuffer(file);
  })
}